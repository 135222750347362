import React from 'react';
import logoFooter from '../images/logo-footer.png';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="border-b border-gray-200 mx-6 lg:mx-24">
        <div className=" sm:grid space-y-8 sm:space-y-0 text-center sm:text-left grid-cols-5 gap-8 pt-16 pb-12">
          <div className="info-footer">
            <div className="logo-footer">
              <img src={logoFooter} className="h-14 mx-auto sm:mx-0" />
            </div>
            <div className="address-footer mt-6 text-sm text-gray-400">
              <p>
                Jl. Sedap Malam No 20 Ketabang, Genteng, Kota Surabaya, Jawa
                Timur, Indonesia, 60272
              </p>
            </div>
          </div>
          <div className="footer-menu">
            <h4>Company</h4>
            <ul className="items-menu">
              <li>
                <a href="#">Project</a>
              </li>
              <li>
                <a href="#">Contact</a>
              </li>
              <li>
                <a href="#">About Us</a>
              </li>
              <li>
                <a href="#">News</a>
              </li>
            </ul>
          </div>
          <div className="footer-menu">
            <h4>Services</h4>
            <ul className="items-menu">
              <li>
                <a href="#">Marketing & Brand Strategy</a>
              </li>
              <li>
                <a href="#">Content Creation</a>
              </li>
              <li>
                <a href="#">Creative Visual Design</a>
              </li>
              <li>
                <a href="#">Multimedia</a>
              </li>
            </ul>
          </div>
          <div className="footer-menu col-span-2 lg:col-span-1">
            <ul className="items-menu mt-12">
              <li>
                <a href="#">Corporate Digitalization</a>
              </li>
              <li>
                <a href="#">Infrastructure</a>
              </li>
              <li>
                <a href="#">Software Development</a>
              </li>
              <li>
                <a href="#">Solution Provider</a>
              </li>
            </ul>
          </div>
          <div className=" col-span-full lg:col-span-1 footer-menu sm:flex justify-between lg:block">
            <div className="contact-footer">
              <h4>Contacts</h4>
              <p>
                <a href="https://wa.me/6282245938587">+62 82245938587</a>
                <br />
                (Bernadinus Arisandi)
              </p>
            </div>
            <div className="contact-footer">
              <h4>Inquiries</h4>
              <p>
                <a href="mailto:contact@hartsimagineering.com">
                  contact@hartsimagineering.com
                </a>
                <br />
              </p>
            </div>

            <div className="contact-footer">
              <h4>Careers</h4>
              <p>
                <a href="mailto:contact@hartsimagineering.com">
                  contact@hartsimagineering.com
                </a>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-6 text-gray-400 mx-6 lg:mx-24 flex justify-between text-sm">
        <span>© 2022 Harts. All Rights Reserved.</span>
        <ul className="flex space-x-3">
          <li>
            <a
              href="https://instagram.com/hartsimagineering"
              className="hover:text-gray-200"
            >
              Instagram
            </a>
          </li>
          <li>
            <i className="fa fa-circle text-[8px] pr-2"></i>{' '}
            <a
              href="https://id.linkedin.com/in/harts-imagineering-89a135154"
              className="hover:text-gray-200"
            >
              LinkedIn
            </a>
          </li>
          {/* <li>
            <i className="fa fa-circle text-[8px] pr-2"></i>{' '}
            <a href="#" className="hover:text-gray-200">
              Twitter
            </a>
          </li> */}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
