/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https:   //www.gatsbyjs.com/docs/use-static-query/
 */

import { css } from '@emotion/react';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Footer from './footer';
import Header from './header';

const footer = css`
  // background-image: radial-gradient(
  //     circle,
  //     rgba(0, 0, 0, 0) 0%,
  //     rgba(0, 0, 0, 0) 86.75%,
  //     rgba(194, 145, 46, 1) 87%,
  //     rgba(194, 145, 46, 1) 87.25%,
  //     rgba(0, 0, 0, 0) 87.5%,
  //     rgba(0, 0, 0, 0) 100%
  //   ),
  //   radial-gradient(
  //     circle,
  //     rgba(0, 0, 0, 0) 0%,
  //     rgba(0, 0, 0, 0) 35%,
  //     rgba(194, 145, 46, 1) 35%,
  //     rgba(194, 145, 46, 1) 55%,
  //     rgba(0, 0, 0, 0) 55%,
  //     rgba(0, 0, 0, 0) 100%
  //   ),
  //   radial-gradient(
  //     circle,
  //     rgba(0, 0, 0, 0) 0%,
  //     rgba(0, 0, 0, 0) 35%,
  //     rgba(194, 145, 46, 1) 35%,
  //     rgba(194, 145, 46, 1) 65%,
  //     rgba(0, 0, 0, 0) 65%,
  //     rgba(0, 0, 0, 0) 100%
  //   );
  // background-position: 96px center, -75px calc(100% + 75px),
  //   calc(100vw - 125px) calc(100% + 125px);
  // background-repeat: no-repeat;
  // background-size: 100% 100%, 150px 150px, 250px 250px;
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query Site {
        site {
          siteMetadata {
            title
          }
        }
        # files: allFile(
        # filter: { name: { in: ["twitter", "facebook", "instagram"] } }
        # ) {
        #   edges {
        #     node {
        #       name
        #       childImageSharp {
        #         fluid(toFormat: WEBP) {
        #           ...GatsbyImageSharpFluid_withWebp
        #         }
        #       }
        #     }
        #   }
        # }
        # logo: file(name: { eq: "logo-bw" }) {
        #   childImageSharp {
        #     fixed(toFormat: WEBP) {
        #...GatsbyImageSharpFixed_withWebp
        #     }
        #   }
        # }
        # daftar: file(name: { eq: "daftar" }) {
        #   childImageSharp {
        #     fixed(width: 200, toFormat: WEBP) {
        #...GatsbyImageSharpFixed_withWebp
        #     }
        #   }
        # }
        # wa: file(name: { eq: "chatwa" }) {
        #   childImageSharp {
        #     fixed(width: 200, toFormat: WEBP) {
        #...GatsbyImageSharpFixed_withWebp
        #     }
        #   }
        # }
      }
    `
  );
  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1),
  //     { threshold: [1] }
  //   );
  //   observer.observe(document.getElementById('fabs'));
  // }, []);

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Harts Imagineering`}
      />
      <main>{children}</main>
      <Footer />
      {/* <footer className="" css={footer}></footer> */}
    </>
  );
};

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// };

export default Layout;
