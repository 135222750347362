import { faBars, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { useTriggerTransition } from 'gatsby-plugin-transition-link';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import fadeTransition from '../components/fadeTransition';
import logo from '../images/logo-harts.png';
import { ANICOLOR } from '../theme/palette';

const Header = ({ siteTitle }) => {
  const location = useLocation();

  const [headerScroll, setHeaderScroll] = useState('');
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 50) {
        setHeaderScroll('minimize');
      } else {
        setHeaderScroll('');
      }
    });
  }, []);

  const data = useStaticQuery(graphql`
    {
      logo: file(name: { eq: "logo" }) {
        childImageSharp {
          fixed(width: 64, toFormat: WEBP) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const fadeNavigate = useTriggerTransition({
    exit: {
      length: 1,
      trigger: ({ exit, node }) =>
        fadeTransition({ exit, node, direction: 'out' }),
    },
    entry: {
      length: 0.5,
      delay: 0.5,
      trigger: ({ exit, node }) =>
        fadeTransition({ exit, node, direction: 'in' }),
    },
  });
  const query = location.search ? qs.parse(location.search.substring(1)) : {};
  const [searchValue, setSearchValue] = useState(query.search || '');
  const handleSearch = event => setSearchValue(event.target.value);

  const isActive = (link, relative = false) =>
    relative
      ? location?.pathname.startsWith(link)
      : location?.pathname === link;

  const searchable =
    location?.pathname.includes(`careers`) ||
    location?.pathname.includes(`news`);

  const search = event => {
    event.preventDefault();

    const path = location?.pathname.includes(`careers`) ? 'careers' : 'news';

    fadeNavigate({
      to: `/${path}?search=${event.currentTarget.elements.search.value.trim()}`,
    });
  };

  const openMobMenu = () => {
    if (openMenu == true) {
      setOpenMenu(false);
    } else {
      setOpenMenu(true);
    }
  };

  const closeMobMenu = () => {
    setOpenMenu(false);
  };

  return (
    <header className={`header-fixed animate-fadeinbottom ${headerScroll}`}>
      <div className="logo-wrapper">
        <a href="/">
          <img src={logo} className="h-14" />
        </a>
      </div>
      <button
        className="btn-menumobile text-2xl text-gray-600 block sm:hidden"
        onClick={x => openMobMenu(x)}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>
      <nav
        className={`main-menu ${
          openMenu == false ? 'hidden' : 'menu-mob'
        } sm:block `}
      >
        <button
          type="button"
          className="btn-close"
          onClick={x => closeMobMenu(x)}
        >
          {' '}
          <FontAwesomeIcon icon={faCircleXmark} />{' '}
        </button>

        {location.pathname == '/' ? (
          <>
            <Link
              activeClass="menu-active"
              to="home"
              className="menu-link"
              spy={true}
              smooth={true}
              duration={500}
            >
              Home
            </Link>
            <Link
              activeClass="menu-active"
              to="about"
              className="menu-link"
              spy={true}
              smooth={true}
              duration={500}
            >
              About Us
            </Link>
            <Link
              activeClass="menu-active"
              to="services"
              className="menu-link"
              spy={true}
              smooth={true}
              duration={500}
            >
              Services
            </Link>
          </>
        ) : (
          <>
            <AniLink paintDrip hex={ANICOLOR} to="/" className="menu-link">
              Home
            </AniLink>
            <AniLink
              paintDrip
              hex={ANICOLOR}
              to="/#aboutContent"
              className="menu-link"
            >
              About Us
            </AniLink>
            <AniLink
              paintDrip
              hex={ANICOLOR}
              to="/#services"
              className="menu-link"
            >
              Services
            </AniLink>
          </>
        )}

        {location.pathname == '/' ? (
          <Link
            activeClass="menu-active"
            to="portfolio"
            className="menu-link"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Portofolio
          </Link>
        ) : (
          <AniLink
            paintDrip
            hex={ANICOLOR}
            to="/portofolio"
            className={`menu-link  ${
              location.pathname.includes('/portofolio/') === true
                ? 'menu-active'
                : ''
            }`}
          >
            Portofolio
          </AniLink>
        )}

        {location.pathname == '/' ? (
          <Link
            activeClass="menu-active"
            to="news"
            className="menu-link"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Blog
          </Link>
        ) : (
          <AniLink
            paintDrip
            hex={ANICOLOR}
            to="/blog"
            className={`menu-link  ${
              location.pathname.includes('/blog/') === true ? 'menu-active' : ''
            }`}
          >
            Blog
          </AniLink>
        )}
      </nav>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
